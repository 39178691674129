import { withTranslation } from "react-i18next";

import "./Team.css";

function Team(props) {
  const { t } = props;

  const teamList = [
    {
      image: 'https://media.licdn.com/dms/image/v2/C4E03AQFcS-M2sgZ_4A/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1600391869333?e=1732147200&v=beta&t=TZ8bgMorIXp3KSYYePddXT0-DNBXoVg60FUkVtZc6oo',
      name: "Fabio Corbetta",
      role: "Co-Founder",
      description: "Team_Member_Fabio",
      linkedin: "https://www.linkedin.com/in/fabio-corbetta-9608921b7/",
    },
    {
      image: 'https://media.licdn.com/dms/image/v2/C4E03AQHa4zj5eSFiow/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1648069628721?e=1732147200&v=beta&t=wuo3uAnJqqFgFI4BEFsGLdE12KVniquHHpus2AZQz-E',
      name: "Marco Corbetta",
      role: "Co-Founder",
      description: "Team_Member_Marco",
      linkedin: "https://www.linkedin.com/in/marco-corbetta/",
    },
  ];

  return (
    <div className="Team w-100" id="About-us-section">
      <p className="small-bold-text grey-text text-center p-0">
        <span className="green-text">-</span> {t("Team_Section_Title")}
      </p>

      <h2 className="large-title grey-text text-center">
        {t("Team_Title_1")}
        <span className="green-text"> {t("Team_Title_2")}</span>
      </h2>

      <p className="medium-text grey-text text-center px-5 my-3">
        {t("Team_Description")}
      </p>

      <a
        style={{textDecorationColor: "#464646"}}
        href="https://www.linkedin.com/company/lonoleggi-it"
        target="_blank"
        rel="noreferrer">
        <p className="medium-bold-text grey-text text-center">
          {t("Team_FollowLinkedIn")}
        </p>
      </a>

      <div className="row mx-0 mt-5">
        {teamList.map(({ image, name, role, description, linkedin }) => {
          return (
            <div
              className="col-12 col-xl-6 mb-3 mb-xl-0"
              key={name}
            >
              <div className="row mx-0 mb-4 text-center text-sm-start external-line">
                <div className="col-12 col-sm-3 mb-2 mb-sm-0">
                  <img src={image} alt={name} height={150} style={{'borderRadius': '50%'}}/>
                </div>
                <div className="col-12 col-sm-9 align-sm-self-center">
                <a
                  style={{textDecorationColor: "#464646"}}
                  href={linkedin}
                  target="_blank"
                  rel="noreferrer">
                    <h3 className="medium-bold-text grey-text m-0 mb-2 mb-sm-0">
                      {name}
                    </h3>
                </a>
                    <blockquote className="blockquote small-text grey-text m-0 pe-sm-5">
                      {t(description)}
                    </blockquote>
                    <p className="medium-text grey-text m-0 pe-sm-5">
                      {t(role)}
                    </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default withTranslation()(Team);
